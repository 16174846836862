<template>
<div>
  <div class="container">
    <div class="header"><router-link to="/myactivity"><img src="../assets/images/back.png" alt=""></router-link> 我的记录</div>
    <div class="blian22"></div>
  </div>
  <div class="record-list mt5">
    <ul>
      <li>
        <span class="text-l">时间</span>
        <span class="text-c">本次金额</span>
        <span class="text-c">审核结果</span>
        <span class="text-c">操作</span>
      </li>
      <li v-for="(item, index) in applys" :key="index">
        <span class="text-l">{{ item.applyDate }}</span>
        <span class="text-c">￥{{ item.money }}</span>
        <span class="text-c" v-if="item.checkStatus==1">审核中</span>
        <span class="text-c" v-if="item.checkStatus==2">已通过</span>
        <span class="text-c" v-if="item.checkStatus==3">已驳回</span>
        <span class="text-c"><router-link :to="{path:'./myrecordcont?id=' + item.applyId}">查看</router-link></span>
      </li>
    </ul>
  </div>
</div>
</template>
<script>
	import { applyRecords } from "@/assets/js/api";
export default {
  name: "myrecord",
  data() {
    return {
    	applys:[],
    }
  },
  methods: {
  	getapplyRecords(){
				applyRecords({}).then(res => {
					if(res.code == 0) {
						console.log(res)
						this.applys = res.applys
					}
				});
			}
  },
  mounted() {
  	this.getapplyRecords()
  }
};
</script>

